<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 my-lg-0">
      <div class="card-title font-weight-bolder text-dark font-size-h4 my-0">
        {{ $t('dashboard.events.title') }}
        <PuSkeleton v-if="!loaded" />
      </div>
    </div>

    <div class="card-body d-flex flex-column flex-grow pt-0">
      <div v-if="events_list.length > 0 && loaded" class="card-body-content">
        <div class="d-flex align-items-center mb-5" v-for="item in events_list.slice(0, 5)" :key="item.id">
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-5">
            <div class="symbol-label" :style="{ backgroundImage: 'url(' + item.cover + ')' }"></div>
          </div>
          <div style="width: 60%; overflow: hidden;" class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
            <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-ellips w-90">
              {{ item.name }}
            </span>
            <span v-if="item.description" class="text-dark-50 font-weight-normal font-size-sm text-ellips"
                  v-html="$options.filters.formatNameLink($options.filters.formatTextLink(item.description))"></span>
          </div>
          <router-link :to="/events/ + item.id" class="d-flex btn btn-light-success btn-md-1">
            <font-awesome-icon icon="chevron-right" class="icon-md"/>
          </router-link>
        </div>
      </div>

      <div v-else class="d-flex flex-grow-1 pb-5">
        <p class="text-dark-50" v-html="$t('dashboard.events.inactive')"></p>
      </div>

      <div>
        <router-link to="/events" class="btn btn-light-primary w-100 mt-5">
          {{ $t('dashboard.events.all_events') }}
        </router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapState, mapActions} from 'vuex';

export default {
   computed: {
    ...mapState({
      // events: state => state.dashboard.events,
    }),
    ...mapGetters({
      events: 'dashboard/events',
    }),
  },
  data: () => {
    return {
      events_list: [],
      loaded: false,
      nowDate: moment().unix()
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'EventsInit'
    ])
  },
  watch: {
    events: {
      handler() {
        if (this.events.length > 0) {
          for (let i in this.events) {
            this.events_list.push({
              id: this.events[i].id,
              name: this.events[i].name,
              cover: this.events[i].cover == null ? require('@/assets/no_image.png') : this.events[i].cover,
              description: this.events[i].description,
              location_id: this.events[i].location_id,
              ts_start: this.events[i].intervals[0].ts_start,
              ts_end: this.events[i].intervals[0].ts_end,
              // daysGone: moment.unix(this.events[i].intervals[0].ts_start).diff(moment(), 'hour')
            })
          }
          // this.events_list = this.events_list.sort((a, b) => b.daysGone - a.daysGone)
          this.events_list.sort((a, b) => b.ts_start - a.ts_start);
        }
        this.loaded = true
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .w-90 {
    max-width: 90%;
  }
  .text-ellips {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    /* text-align: justify; */
  }

  .card-custom {
    height: 304px!important;
  }
  .card-body-content {
    overflow-y: scroll!important;
  }
  @media (max-width: 991px) {
    .card-custom {
      height: 343px!important;
    }
  }
</style>
